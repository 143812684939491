export const getStudents = state => {
  return state.agency.students;
};

export const getAgencies = state => {
  return state.agency.agencies;
};

export const getStudent = (state, props) => {
  return state.agency.students.find(student => {
    return student.studentId === props.studentId;
  }) || { firstName: '', lastName: ''};
};
