import * as types from '../constants/ActionTypes';
import { callApi } from '../utils/ApiUtils';
import Notify from '../utils/Notify';
import { redirect } from './UserActions';

// TODO: fetch specific assessment or figure out how to get id
export const fetchAssessment = () => async dispatch => {
  const { error, json } = await callApi('/api/assessment');

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Assessment'
    });
  } else {
    dispatch({
      type: types.FETCHED_ASSESSMENT,
      assessment: json
    });
  }
};

export const fetchAssessmentResults = (assessmentId) => async dispatch => {
  const { error, json } = await callApi(`/api/assessment/${assessmentId}/results`);

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Assessment Results'
    });
  } else {
    dispatch({
      type: types.FETCHED_ASSESSMENT_RESULTS,
      assessmentId,
      results: json
    });
  }
};

export const fetchAssessments = (studentId = '') => async dispatch => {
  const { error, json } = await callApi(`/api/assessments?studentId=${studentId}`);

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Assessments'
    });
  } else {
    dispatch({
      type: types.FETCHED_ASSESSMENTS,
      assessments: json
    });
  }
};

export const submitAssessment = (assessmentId, answers) => async dispatch => {
  const answersArray = Object.keys(answers).map(key => ({
    questionId: key,
    answer: answers[key]
  }));

  const options = {
    method: 'POST',
    body: JSON.stringify({ answers: answersArray, assessmentId })
  };

  const { error, json } = await callApi('/api/assessment/submit', options);

  if (error) {
    Notify.error('Error submitting assessment');
  } else {
    dispatch({
      type: types.SUBMITTED_ASSESSMENT,
      assessments: json
    });
    Notify.success('Assessment submitted');
    dispatch(redirect('/'));
  }
};
