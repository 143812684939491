import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';

import './Question.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/es/Col";
import Container from "react-bootstrap/Container";

class Question extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: (props.selectedAnswer || props.selectedAnswer === 0)
        ? props.selectedAnswer
        : -1
    };

    this.select = this.select.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedItem: (nextProps.selectedAnswer || nextProps.selectedAnswer === 0)
        ? nextProps.selectedAnswer
        : -1
    });
  }

  select(idx) {
    this.setState({
      selectedItem: idx
    });

    this.props.answer(this.props.question.questionId, idx);
  }

  isSelected(optionIdx) {
    return optionIdx === this.state.selectedItem
      ? 'Question__option--selected'
      : '';
  }

  render() {
    const { question } = this.props;
    return (
      <Card className="text-center">
        <Card.Body className="text-center">
          <Card.Title as="h3">{question.text}</Card.Title>
          <div className="Question__options">
            <Container>
              <Row className="d-xs-block d-sm-none">
                <Col lg="2" md="4" sm="6">
                  Disagree
                </Col>
              </Row>
              <Row>
                <Col lg="2" md="4" sm="6">
                  <div
                      className={`Question__option ${this.isSelected(0)}`}
                      onClick={() => this.select(0)}
                  >
                    0
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div
                      className={`Question__option ${this.isSelected(1)}`}
                      onClick={() => this.select(1)}
                  >
                    1
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div
                      className={`Question__option ${this.isSelected(2)}`}
                      onClick={() => this.select(2)}
                  >
                    2
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div
                      className={`Question__option ${this.isSelected(3)}`}
                      onClick={() => this.select(3)}
                  >
                    3
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div
                      className={`Question__option ${this.isSelected(4)}`}
                      onClick={() => this.select(4)}
                  >
                    4
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div
                      className={`Question__option ${this.isSelected(5)}`}
                      onClick={() => this.select(5)}
                  >
                    5
                  </div>
                </Col>
              </Row>
            <Row>
              <Col lg="2" md="4" sm="6" className="d-none d-sm-block">
                Disagree
              </Col>
              <Col lg={{span: 2, offset: 8}} md="4" sm="6">
                Agree
              </Col>
            </Row>
          </Container>
          </div>
          {/*<div className="Question__footer">*/}
          {/*  <div*/}
          {/*    className="Question__previous"*/}
          {/*    onClick={this.props.previousQuestion}*/}
          {/*  >*/}
          {/*    Previous*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    className="Question__next"*/}
          {/*    onClick={this.props.nextQuestion}*/}
          {/*  >*/}
          {/*    Next*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Card.Body>
      </Card>
    );
  }
}

export default Question;
