import React, { Component } from 'react';
import DatePicker from 'react-datepicker/es';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default class CreateAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date()
    };

    this.createAssignment = this.createAssignment.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  createAssignment() {
    this.props.createAssignment(this.state.startDate);
  }

  changeDate(date) {
    this.setState({
      startDate: date
    });
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h3">Create Assignment</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <div>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.changeDate}
                />
              </div>
            </Form.Group>
            <Button variant="primary" onClick={this.createAssignment}>
              Create
            </Button>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}
