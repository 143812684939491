export const callApi = (url, options = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  options.headers = options.headers || defaultHeaders;

  return fetch(url, options)
    .then(
      response =>
        response.ok
          ? response.json()
          : response.json().then(error => {
              console.error(error);
              return Promise.reject(error);
            }),
      error => Promise.reject(error)
    )
    .then(json => ({ json }), error => ({ error }))
    .catch(error => ({ error }));
};
