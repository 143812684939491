import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../FF_Logo.png';
import * as Icon from 'react-feather';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import './TopNav.css';
import Row from "react-bootstrap/Row";

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };

    this.changeRoute = this.changeRoute.bind(this);
    this.setNavExpanded = this.setNavExpanded.bind(this);
  }

  async logout() {
    // TODO: Only redirects in prod mode
    await fetch('api/user/logout');
    window.location = '/api/user/logout';
  }

  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }

  closeNav() {
    this.setState({ navExpanded: false });
  }

  support() {
    window.location.href = "mailto:support@feetfirstnp.org?subject=Assessment Support";
  }

  changeRoute(path) {
    this.props.history.push(path);
    this.closeNav();
  }

  authenticatedNav() {
    let navItems;
    switch (this.props.user.scope) {
      case 'admin':
        return this.adminNav();
      case 'agency':
        return this.agencyNav();
      case 'student':
        return this.studentNav();
      default:
        return null;
    }
  }

  agencyNav() {
    const studentsActive = this.props.history.location.pathname.includes('/students');

    return (
      <React.Fragment>
        <Nav.Item as="li">
          <Nav.Link
            active={studentsActive}
            eventKey="/students"
            onClick={() => this.changeRoute('/students')}
          >
            <Icon.Users className="Icon" size={15} />
            Students
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="/assignments"
            onClick={() => this.changeRoute('/assignments')}
          >
            <Icon.Book className="Icon" size={15} />
            Assignments
          </Nav.Link>
        </Nav.Item>
      </React.Fragment>
    );
  }

  studentNav() {
    const selfAssessmentsActive = this.props.history.location.pathname.includes('/self-assessments');

    return (
      <React.Fragment>
        <Nav.Item as="li">
          <Nav.Link
            active={selfAssessmentsActive}
            eventKey="/self-assessments"
            onClick={() => this.changeRoute('/self-assessments')}
          >
            <Icon.Book className="Icon" size={15} />
            Self Assessments
          </Nav.Link>
        </Nav.Item>
      </React.Fragment>
    );
  }

  adminNav() {
    // TODO: add admin nav items
    return null;
  }

  navItems() {
    return (
      <Nav activeKey={this.props.history.location.pathname} as="ul">
        <Nav.Item as="li">
          <Nav.Link eventKey="/" onClick={() => this.changeRoute('/')}>
            <Icon.Home className="Icon" size={15} />
            Home
          </Nav.Link>
        </Nav.Item>
        {this.props.user && this.authenticatedNav()}
      </Nav>
    );
  }

  render() {
    if (this.props.history.location.pathname === '/login' || this.props.history.location.pathname === '/register') {
      return null;
    }

    return (
      <div className="TopNav">
        <div className="TopNav__header">
          <Container>
            <Navbar
              bg="white"
              expand="lg"
              onToggle={this.setNavExpanded}
              expanded={this.state.navExpanded}
            >
              <Navbar.Brand onClick={() => this.changeRoute('/')}>
                <img className="TopNav__logo" src={logo} height={50} />
                Feet First
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              {this.props.user && (
                <Navbar.Collapse className="justify-content-end">
                  <Container className="d-block d-lg-none">
                    {this.navItems()}
                  </Container>
                  <NavDropdown
                    title={this.props.user.firstName}
                    id="basic-nav-dropdown"
                  >
                    {this.props.user.scope === 'agency' &&
                        <NavDropdown.Item onClick={this.support}>
                          Support
                        </NavDropdown.Item>
                    }
                    <NavDropdown.Item onClick={this.logout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar.Collapse>
              )}
            </Navbar>
          </Container>
        </div>
        <div className="TopNav__header d-none d-lg-block">
          <Container>
            {this.navItems()}
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(TopNav);
