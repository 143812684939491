import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { fetchAssessmentResults } from '../../actions/AssessmentActions';
import { fetchStudents } from '../../actions/AgencyActions';
import { getStudent } from '../../selectors/AgencySelectors';
import { getAssessmentResults } from '../../selectors/AssessmentSelectors';

import Container from "react-bootstrap/Container";

class StudentAssessment extends Component {
  componentDidMount() {
    this.props.fetchStudents();
    this.props.fetchAssessmentResults(this.props.match.params.assessmentId)
  }

  render() {
    const answerArray = [0, 1, 2, 3, 4, 5];

    const { student } = this.props;
    return (
      <Container>
        <Row>
          <Col className="SignUp__buttons" md="12">
            <h1 className="page-title">
              {`${student.firstName} ${student.lastName}`}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h3">Assessment Results</Card.Title>
              </Card.Header>
              <Table responsive striped>
                <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
                </thead>
                <tbody>
                {this.props.results.map(question => (
                  <tr>
                    <td>
                      {question.text}
                    </td>
                    <td>
                      {answerArray[question.answer]}
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  results: getAssessmentResults(state, props.match.params),
  student: getStudent(state, props.match.params)
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAssessmentResults, fetchStudents }
  )(StudentAssessment)
);
