import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PrivateRoute from './components/util/PrivateRoute';
import { fetchUser } from './actions/UserActions';
import { getUser } from './selectors/UserSelectors';

import TopNav from './components/TopNav';
import './App.css';
import Register from './components/Register';
import Login from './components/Login';
import Students from './components/agency/Students';
import Student from './components/agency/Student';
import Dashboard from './components/dashboard';
import Assessment from './components/student/Assessment';
import AssessmentsPage from './components/student/AssessmentsPage';
import Assignments from './components/agency/Assignments';
import Assignment from './components/agency/Assignment';
import 'react-notifications/lib/notifications.css';
import 'react-datepicker/dist/react-datepicker.css';
import { NotificationContainer } from 'react-notifications';
import StudentAssessment from "./components/agency/StudentAssessment";
import { createBrowserHistory } from 'history';
import Redirecter from './components/Redirecter';

export const history = createBrowserHistory();


class App extends Component {
  componentDidMount() {
    if (window.location.pathname !== '/register') {
      this.props.fetchUser();
    }
  }

  authenticated() {
    const { cookies } = this.props;
    const authCookie = cookies.get('sid');

    return authCookie && authCookie.length > 0;
  }

  authorized(scope) {
    const { cookies } = this.props;
    const authCookie = cookies.get('sid');
    const roleCookie = cookies.get('scope');

    return authCookie && authCookie.length > 0 && roleCookie === scope;
  }

  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Redirecter />
          <NotificationContainer />
          <TopNav user={this.props.user} />
          <div className="App__content">
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/privacy-policy" component={Register} />
            <Route exact path="/dashboard/:tab" component={Dashboard} />
            <PrivateRoute
              authenticated={this.authorized('student')}
              path="/self-assessments"
              component={AssessmentsPage}
            />
            <PrivateRoute
              authenticated={this.authorized('student')}
              path="/self-assessments/:assessmentId"
              component={Assessment}
            />
            <PrivateRoute
              authenticated={this.authenticated()}
              path="/"
              component={Dashboard}
            />
            <PrivateRoute
              authenticated={this.authorized('agency')}
              path="/students"
              component={Students}
            />
            <PrivateRoute
              authenticated={this.authorized('agency')}
              path="/students/:studentId"
              component={Student}
            />
            <PrivateRoute
                authenticated={this.authorized('agency')}
                path="/students/:studentId/assessment/:assessmentId"
                component={StudentAssessment}
            />
            <PrivateRoute
              authenticated={this.authorized('agency')}
              path="/assignments"
              component={Assignments}
            />
            <PrivateRoute
              authenticated={this.authorized('agency')}
              path="/assignments/:assignmentId"
              component={Assignment}
            />
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: getUser(state)
});

export default withCookies(
  connect(
    mapStateToProps,
    { fetchUser }
  )(App)
);
