import React, {Component, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { callApi } from '../utils/ApiUtils';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { register } from '../actions/UserActions';


import './Register.scss';
import Col from 'react-bootstrap/Col';

const  Register = (props) => {
  const [state, setState] = useState({
    inviteToken: '',
    validated: false,
    validating: true
  });

  useEffect(() => {
    const validateToken = async () => {
      const queryParams = queryString.parse(props.location.search);

      if (!queryParams.inviteToken) {
        props.history.push('/login')
      } else {
        const options = {
          method: 'POST',
          body: JSON.stringify({ inviteTokenId: queryParams.inviteToken })
        };

        const { error, json } = await callApi('/api/user/validate-token', options);

        if (!json.valid) {
          props.history.push('/login')
        } else {
          setState({ inviteToken: queryParams.inviteToken, validating: false });
        }
      }
    };

    validateToken()
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setState(state => ({
      ...state,
        validated: true
    }));

    const email = form.elements.email.value;
    const password = form.elements.password.value;
    const firstName = form.elements.firstName.value;
    const lastName = form.elements.lastName.value;
    const title = form.elements.title.value;
    const schoolName = form.elements.schoolName.value;
    const schoolAddress1 = form.elements.schoolAddress1.value;
    const schoolAddress2 = form.elements.schoolAddress2.value;
    const schoolCity = form.elements.schoolCity.value;
    const schoolState = form.elements.schoolState.value;
    const schoolZip = form.elements.schoolZip.value;

    props.register(state.inviteToken, email, password, firstName, lastName, title, schoolName, schoolAddress1, schoolAddress2, schoolCity, schoolState, schoolZip);
  };

  const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

  if (state.validating) {
    return null;
  }

  return (
    <div className="Login container">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Modal.Dialog size="lg" centered>
            <Modal.Header>
              <Modal.Title className="text-center" as="h3">
                Create Account
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={e => handleSubmit(e)} validated={state.validated}>
                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control required type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control required type="password" placeholder="Enter password" />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter first name" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter last name" />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="title">
                  <Form.Label>Title at School</Form.Label>
                  <Form.Control required placeholder="Principal" />
                </Form.Group>

                <Form.Group controlId="schoolName">
                  <Form.Label>School Name</Form.Label>
                  <Form.Control required placeholder="Aledo High School" />
                </Form.Group>

                <Form.Group controlId="schoolAddress1">
                  <Form.Label>School Address</Form.Label>
                  <Form.Control required placeholder="1234 Main St" />
                </Form.Group>

                <Form.Group controlId="schoolAddress2">
                  <Form.Control placeholder="Apartment, studio, or floor" />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} controlId="schoolCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control required />
                  </Form.Group>

                  <Form.Group as={Col} controlId="schoolState">
                    <Form.Label>State</Form.Label>
                    <Form.Control required as="select">
                      {states.map(state => (
                        <option key={state}>{state}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} controlId="schoolZip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control required />
                  </Form.Group>
                </Form.Row>

                <Form.Group id="formNotification">
                  <Form.Label>By clicking Register, you agree to our <a href="/PrivacyPolicy.doc">Privacy Policy</a></Form.Label>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Register
                </Button>
              </Form>
            </Modal.Body>
          </Modal.Dialog>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(
  connect(
    null,
    { register }
  )(Register)
);
