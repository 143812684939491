import * as types from '../constants/ActionTypes';

const initialState = {
  agencies: [],
  students: []
};

export default function agency(state = initialState, action) {
  switch (action.type) {
    case types.FETCHED_STUDENTS:
      return {
        ...state,
        students: [...action.students]
      };
    case types.FETCHED_AGENCIES:
      return {
        ...state,
        agencies: [...action.agencies]
      };
    default:
      return state;
  }
}
