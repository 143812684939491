import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { login } from '../actions/UserActions';
import { fetchAgencies } from '../actions/AgencyActions';
import { getUser } from '../selectors/UserSelectors';
import {getAgencies} from "../selectors/AgencySelectors";

class Login extends Component {
  componentDidMount() {
    if (this.props.user) {
      this.props.history.push('/');
    }
    this.props.fetchAgencies();
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const agencyId = form.elements.agency.value;
    const email = form.elements.email.value;
    const password = form.elements.password.value;

    this.props.login(agencyId, email, password);
  }

  render() {
    return (
      <div className="Login container">
        <Row className="justify-content-md-center">
          <Col md="6">
            <Modal.Dialog size="lg" centered>
              <Modal.Header>
                <Modal.Title className="text-center" as="h3">
                  Login to Feet First
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={e => this.handleSubmit(e)}>
                  <Form.Group controlId="agency">
                    <Form.Label>School</Form.Label>
                    <Form.Control as="Select">
                      {this.props.agencies.map(agency => {
                        return (
                          <option value={agency.agencyId}>{agency.schoolName}</option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Username</Form.Label>
                    <Form.Control placeholder="Enter username" />
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Modal.Body>
            </Modal.Dialog>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  agencies: getAgencies(state),
  user: getUser(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAgencies,login }
  )(Login)
);
