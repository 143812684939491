import React, { Component } from 'react';
import Papa from 'papaparse';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default class ImportStudents extends Component {
  importStudents(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const csv = form.elements.csv;
    window.csv = csv;
    Papa.parse(form.elements.csv.files[0], {
      complete: ({ data }) => {
        this.props.import(data);
      }
    })
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h3">Import Students from CSV</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={e => this.importStudents(e)}>
            <Form.Group controlId="csv">
              <Form.Label>Select CSV</Form.Label>
              <Form.Control type="file" accept=".csv" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Import
            </Button>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}
