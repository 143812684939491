import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const AssignmentResults = ({assignmentResults, changeQuestionById, questions}) => {
  if (!assignmentResults) {
    return null;
  }

  const red = '#e03333';

  const data = Object.keys(assignmentResults).map((questionId, idx) => {
    const nonZero = assignmentResults[questionId]
      .filter(answer => answer !== 0);

    const nonZeroNum = nonZero.reduce((sum, num) => sum + num, 0);
    const sum = assignmentResults[questionId]
      .reduce((sum, answer, idx) => (sum + answer * idx), 0);
    const avg = (sum / nonZeroNum) || 0;
    return {
      color: !questions.find(question => question.questionId === questionId).positive ? red : undefined,
      y: avg,
      x: idx + 1,
      questionId,
      name: questions.find(question => question.questionId === questionId).text
    }
  });
  const series1 = data.filter(point => !point.color);
  const series2 = data.filter(point => point.color);

  const options = {
    chart: {
      type: 'column'
    },
    credits: {
      enabled: false
    },
    title: {
      text: 'Results'
    },
    xAxis: {
      crosshair: true,
      title: {
        text: 'Question'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Average Answer'
      }
    },
    tooltip: {
      formatter: function () {
        return this.point.name;
      }
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (e) => {
              changeQuestionById(e.point.questionId);
            }
          }
        }
      },
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        grouping: false
      }
    },
    series: [
      {
        showInLegend: true,
        name: 'Higher is better',
        data: series1
      },
      {
        color: red,
        showInLegend: true,
        name: 'Lower is better',
        data: series2
      },
    ]
  };


  return (
    <div>
      {options && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      )}
    </div>
  );
};