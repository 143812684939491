import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getAssessment } from '../../selectors/AssessmentSelectors';
import {
  fetchAssessment,
  submitAssessment
} from '../../actions/AssessmentActions';
import Button from 'react-bootstrap/Button';

import './Assessment.css';
import Question from './Question';

class Assessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: {},
      canSubmit: false
    };

    this.answer = this.answer.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.fetchAssessment();
  }

  answer(questionId, answer) {
    this.setState({
      answers: {
        ...this.state.answers,
        [questionId]: answer
      }
    });
  }

  canSubmit() {
    if (!this.props.assessment) {
      return false;
    }
    const { questions } = this.props.assessment;
    return questions.length === Object.keys(this.state.answers).length;
  }

  submit() {
    this.props.submitAssessment(
      this.props.match.params.assessmentId,
      this.state.answers
    );
  }

  render() {
    const { assessment } = this.props;
    const { answers } = this.state;

    return (
      <div className="Assessment container">
        <Row className="justify-content-md-center">
          <Col className="SignUp__buttons" md="12">
            <h1 className="page-title">New Assessment</h1>
            {assessment &&
              assessment.questions.map(question => (
                <Question
                  answer={this.answer}
                  selectedAnswer={answers[question.questionId]}
                  question={question}
                  nextQuestion={this.nextQuestion}
                  previousQuestion={this.previousQuestion}
                />
              ))}
            <Button disabled={!this.canSubmit()} variant="primary" onClick={this.submit}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assessment: getAssessment(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAssessment, submitAssessment }
  )(Assessment)
);
