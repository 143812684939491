import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {callApi} from "../../utils/ApiUtils";

class AdminDashboard extends Component {
  async inviteAgency(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const email = form.elements.email.value;

    const options = {
      method: 'POST',
      body: JSON.stringify({ email })
    };

    const { error, json } = await callApi('/api/email/register', options);
    console.log(json)
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="SignUp__buttons" md="12">
            <h1 className="page-title">Dashboard</h1>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h3">Invite Agency</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={e => this.inviteAgency(e)}>
                  <Form.Group controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default AdminDashboard;
