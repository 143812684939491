import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import Assessments from './Assessments';

export default class StudentDashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="SignUp__buttons" md="12">
            <h1 className="page-title">Dashboard</h1>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Assessments />
          </Col>
          {/*<Col md="6">*/}
          {/*  <Alert variant="primary">*/}
          {/*    <Alert.Link href="/self-assessment">*/}
          {/*      You have a new assessment.{' '}*/}
          {/*    </Alert.Link>*/}
          {/*    Please fill it out before May 3rd.*/}
          {/*  </Alert>*/}
          {/*</Col>*/}
        </Row>
      </React.Fragment>
    );
  }
}

