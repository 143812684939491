import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";

import Assessments from './Assessments';

export default class AssessmentsPage extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col className="SignUp__buttons" md="12">
            <h1 className="page-title">Assessments</h1>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Assessments />
          </Col>
        </Row>
      </Container>
    );
  }
}

