import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default class AddStudent extends Component {
  addStudent(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const sid = form.elements.sid.value;
    const firstName = form.elements.firstName.value;
    const lastName = form.elements.lastName.value;

    this.props.addStudent(sid, firstName, lastName);

    form.elements.sid.value = '';
    form.elements.firstName.value = '';
    form.elements.lastName.value = '';
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h3">Add Student</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={e => this.addStudent(e)}>
            <Form.Group controlId="firstName">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" placeholder="Enter first name" />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control type="text" placeholder="Enter last name" />
            </Form.Group>
            <Form.Group controlId="sid">
              <Form.Label>SID</Form.Label>
              <Form.Control type="text" placeholder="Enter SID" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}
