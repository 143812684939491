import * as types from '../constants/ActionTypes';

const initialState = {
  error: null,
  redirectPath: null,
  user: null
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case types.FETCHED_USER:
      return {
        ...state,
        user: { ...action.user }
      };
    case types.REDIRECT:
      return {
        ...state,
        redirectPath: action.redirectPath
      };
    default:
      return state;
  }
}
