import * as types from '../constants/ActionTypes';
import { callApi } from '../utils/ApiUtils';
import Notify from '../utils/Notify';

export const login = (agencyId, email, password) => async dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ agencyId, email, password })
  };

  const { error, json } = await callApi('/api/user/login', options);

  if (error) {
    Notify.error('Incorrect username or password');
  } else {
    dispatch({
      type: types.FETCHED_USER,
      user: json
    });

    window.location = '/';
  }
};

export const register = (inviteTokenId, email, password, firstName, lastName, title, schoolName, schoolAddress1, schoolAddress2, schoolCity, schoolState, schoolZip) => async dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ inviteTokenId, email, password, firstName, lastName, title, schoolName, schoolAddress1, schoolAddress2, schoolCity, schoolState, schoolZip })
  };

  const { error, json } = await callApi('/api/user/register', options);

  if (error) {
    Notify.error('Error creating account');
  } else {
    Notify.success('Account created');
    setTimeout(() => {
      window.location = '/login';
    }, 1500);
  }
};

export const fetchUser = () => async dispatch => {
  const { error, json } = await callApi('/api/user');

  if (error) {
    dispatch(redirect('/login'));
  } else {
    dispatch({
      type: types.FETCHED_USER,
      user: json
    });
  }
};

export const redirect = (redirectPath) => ({
  type: types.REDIRECT,
  redirectPath
});
