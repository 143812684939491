import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import { getStudents } from '../../selectors/AgencySelectors';
import { fetchStudents } from '../../actions/AgencyActions';
import Header from '../Header';

class Students extends Component {
  componentDidMount() {
    this.props.fetchStudents();
  }

  render() {
    return (
      <Container>
        <Row>
          <Header title="Students" />
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h3">Students</Card.Title>
              </Card.Header>
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.students.map(student => (
                    <tr onClick={() => this.props.history.push(`/students/${student.studentId}`)}>
                      <td>{student.firstName}</td>
                      <td>{student.lastName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  students: getStudents(state)
});

export default withRouter(
 connect(
  mapStateToProps,
  { fetchStudents }
)(Students)
);
