import React from 'react';
import Col from 'react-bootstrap/Col';

const Header = ({ title }) => {
  return (
    <Col className="SignUp__buttons" md="12">
      <h1 className="page-title">{title}</h1>
    </Col>
  );
};

export default Header;
