import React, { Component } from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import { getAssessments } from '../../selectors/AssessmentSelectors';
import { fetchAssessments } from '../../actions/AssessmentActions';
import Button from 'react-bootstrap/Button';

class Assessments extends Component {
  componentDidMount() {
    this.props.fetchAssessments(this.props.match.params.studentId);
  }

  clickAssessment(assessment) {
    if (this.props.agency) {
      const { studentId } = this.props.match.params;
      this.props.history.push(`/students/${studentId}/assessment/${assessment.assessmentId}`);
    }
  }

  completed(assessment) {
    const dueDate = new Date(assessment.dueDate);
    const today = new Date();

    if (assessment.completedDate) {
      return dateFns.format(assessment.completedDate, 'MM/DD/YYYY')
    } else if (dateFns.compareAsc(dueDate, today) < 0) {
      return 'Missed';
    } else {
      if (this.props.agency) {
        return 'Incomplete';
      } else {
        return (
          <Button
            variant="primary"
            onClick={() =>
              this.props.history.push(
                `/self-assessments/${assessment.assessmentId}`
              )
            }
          >
            Start
          </Button>
        );
      }
    }
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h3">Assessments</Card.Title>
        </Card.Header>
        <Table responsive striped hover={this.props.agency}>
          <thead>
          <tr>
            <th>Start</th>
            <th>Due</th>
            <th>Completed</th>
          </tr>
          </thead>
          <tbody>
          {this.props.assessments.map(assessment => (
            <tr onClick={() => this.clickAssessment(assessment)}>
              <td>
                {dateFns.format(assessment.startDate, 'MM/DD/YYYY')}
              </td>
              <td>
                {dateFns.format(assessment.dueDate, 'MM/DD/YYYY')}
              </td>
              <td>{this.completed(assessment)}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assessments: getAssessments(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAssessments }
  )(Assessments)
);
