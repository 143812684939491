import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getRedirectPath } from '../selectors/UserSelectors';
import { redirect } from '../actions/UserActions';

const Redirecter = ({ history, path, redirect }) => {
  useEffect(() => {
    if (path) {
      history.push(path);
      redirect(null);
    }
  }, [path]);

  return null;
};

const mapStateToProps = (state) => ({
  path: getRedirectPath(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    { redirect }
  )(Redirecter)
);
