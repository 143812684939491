// User
export const FETCHED_USER = 'FETCHED_USER';
export const ERROR = 'ERROR';
export const REDIRECT = 'REDIRECT';

// Assessment
export const FETCHED_ASSESSMENT = 'FETCHED_ASSESSMENT';
export const FETCHED_ASSESSMENT_RESULTS = 'FETCHED_ASSESSMENT_RESULTS';
export const FETCHED_ASSESSMENTS = 'FETCHED_ASSESSMENTS';
export const SUBMITTED_ASSESSMENT = 'SUBMITTED_ASSESSMENT';

// Assignment
export const FETCHED_ASSIGNMENTS = 'FETCHED_ASSIGNMENTS';
export const DELETED_ASSIGNMENT = 'DELETED_ASSIGNMENT';
export const FETCHED_ASSIGNMENT_RESULTS = 'FETCHED_ASSIGNMENTS_RESULTS';
export const FETCHED_PERCENTAGE_COMPLETED = 'FETCHED_PERCENTAGE_COMPLETED';

// Agency
export const FETCHED_STUDENTS = 'FETCHED_STUDENTS';
export const FETCHED_AGENCIES = 'FETCHED_AGENCIES';
