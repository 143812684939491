import * as types from '../constants/ActionTypes';

const initialState = {
  assessment: null,
  assessments: [],
  results: {}
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case types.FETCHED_ASSESSMENT:
      return {
        ...state,
        assessment: { ...action.assessment }
      };
    case types.FETCHED_ASSESSMENT_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          [action.assessmentId]: [...action.results]
        }
      };
    case types.FETCHED_ASSESSMENTS:
      return {
        ...state,
        assessments: [...action.assessments]
      };
    default:
      return state;
  }
}
