import * as types from '../constants/ActionTypes';

const initialState = {
  assignments: [],
  assignmentResults: {},
  percentageCompleted: 0
};

export default function assignment(state = initialState, action) {
  switch (action.type) {
    case types.FETCHED_ASSIGNMENTS:
      return {
        ...state,
        assignments: [...action.assignments]
      };
    case types.DELETED_ASSIGNMENT:
      const deleteIndex = state.assignments.findIndex(a => a.assignmentId === action.assignmentId);
      return {
        ...state,
        assignments: [
          ...state.assignments.slice(0, deleteIndex),
          ...state.assignments.slice(deleteIndex + 1)
        ]
      };
    case types.FETCHED_ASSIGNMENT_RESULTS:
      return {
        ...state,
        assignmentResults: {
          ...state.assignmentResults,
          [action.assignmentId]: action.assignmentResults
        }
      };
    case types.FETCHED_PERCENTAGE_COMPLETED:
      return {
        ...state,
        percentageCompleted: action.percentageCompleted
      };
    default:
      return state;
  }
}
