import React, { Component } from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { getAssignments } from '../../selectors/AssignmentSelectors';
import { deleteAssignment, fetchAssignments } from '../../actions/AssignmentActions';
import Card from 'react-bootstrap/Card';
import Header from '../Header';

// import './Assignments.css';

class Assignments extends Component {
  componentDidMount() {
    this.props.fetchAssignments();
  }

  answer(questionId, answer) {
    this.setState({
      answers: {
        ...this.state.answers,
        [questionId]: answer
      }
    });
  }

  submit() {
    this.props.submitAssessment(
      this.props.match.params.assessmentId,
      this.state.answers
    );
  }

  deleteAssignment(event, assignmentId) {
    event.stopPropagation();
    this.props.deleteAssignment(assignmentId);
  }

  deleteButton(assignment) {
    const dueDate = new Date(assignment.dueDate);
    const today = new Date();

    if (!assignment.started && dateFns.compareAsc(dueDate, today) > 0) {
      return (
        <Button
          variant="primary"
          onClick={(event) =>
            this.deleteAssignment(
              event,
              assignment.assignmentId
            )
          }
        >
          Delete
        </Button>
      );
    }

    return null;
  }

  render() {
    const { assignments } = this.props;

    return (
      <div className="Assessment container">
        <Row className="justify-content-md-center">
          <Header title="Assignments" />
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h3">Assignments</Card.Title>
              </Card.Header>
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Start Date</th>
                    <th>Due Date</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {assignments.map(assignment => (
                    <tr
                      onClick={() => this.props.history.push(`/assignments/${assignment.assignmentId}`)}
                      data-id={assignment.assignmentId}
                    >
                      <td>Self</td>
                      <td>
                        {dateFns.format(assignment.startDate, 'MM/DD/YYYY')}
                      </td>
                      <td>
                        {dateFns.format(assignment.dueDate, 'MM/DD/YYYY')}
                      </td>
                      <td>
                        {
                          this.deleteButton(assignment)
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assignments: getAssignments(state)
});

export default connect(
  mapStateToProps,
  { deleteAssignment, fetchAssignments }
)(Assignments);
