import React, { Component } from 'react';
import StudentDashboard from '../student/StudentDashboard';
import AdminDashboard from './AdminDashboard';
import AgencyDashboard from '../agency/dashboard/AgencyDashboard';
import Container from 'react-bootstrap/Container';

class Dashboard extends Component {
  userDashboard() {
    switch (this.props.user.scope) {
      case 'admin':
        return <AdminDashboard />;
      case 'student':
        return <StudentDashboard />;
      case 'agency':
        return <AgencyDashboard />;
      default:
        return <div>Dashboard unavailable</div>;
    }
  }
  render() {
    if (!this.props.user) {
      return null;
    }

    return <Container>{this.userDashboard()}</Container>;
  }
}

export default Dashboard;
