import * as types from '../constants/ActionTypes';
import { callApi } from '../utils/ApiUtils';
import Notify from '../utils/Notify';
import { getUser } from '../selectors/UserSelectors';

export const fetchStudents = () => async (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);

  const { error, json } = await callApi(`/api/students/${user.agencyId}`);

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Students'
    });
  } else {
    dispatch({
      type: types.FETCHED_STUDENTS,
      students: json.students
    });
  }
};

export const fetchAgencies = () => async (dispatch) => {
  const { error, json } = await callApi('/api/agencies');

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Agencies'
    });
  } else {
    dispatch({
      type: types.FETCHED_AGENCIES,
      agencies: json.agencies
    });
  }
};

export const addStudent = (
  password,
  firstName,
  lastName
) => async dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      password,
      firstName: firstName.trim(),
      lastName: lastName.trim()
    })
  };

  const { error, json } = await callApi('/api/student', options);

  if (error) {
    Notify.error('Error adding student');
  } else {
    Notify.success('Added student');
  }
};

export const importStudents = (rows) => async dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      rows,
    })
  };

  const { error, json } = await callApi('/api/students/import', options);

  if (error) {
    Notify.error('Error importing students');
  } else {
    Notify.success('Imported students');
  }
};
