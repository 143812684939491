import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import AddStudent from './AddStudent';
import ImportStudents from './ImportStudents';
import { addStudent, importStudents } from '../../../actions/AgencyActions';
import {
  createAssignment,
  fetchAssignments,
  fetchPercentageCompleted
} from '../../../actions/AssignmentActions';
import {
  assignmentsRemaining,
  getPercentageCompleted
} from '../../../selectors/AssignmentSelectors';
import Header from '../../Header';
import CreateAssignment from './CreateAssignment';

class AgencyDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date()
    };

    this.createAssignment = this.createAssignment.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  componentDidMount() {
    this.props.fetchPercentageCompleted();
    this.props.fetchAssignments();
  }

  addStudent(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const email = form.elements.username.value;
    const password = form.elements.password.value;
    const firstName = form.elements.firstName.value;
    const lastName = form.elements.lastName.value;

    this.props.addStudent(email, password, firstName, lastName);

    form.elements.username.value = '';
    form.elements.password.value = '';
    form.elements.firstName.value = '';
    form.elements.lastName.value = '';
  }

  createAssignment() {
    this.props.createAssignment();
  }

  changeDate(date) {
    this.setState({
      startDate: date
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Header title="Dashboard" />
        </Row>
        <Row>
          <Col md="6">
            <AddStudent addStudent={this.props.addStudent} />
          </Col>
          <Col md="6">
            <Row>
              <Col md="6">
                <Card className="text-center">
                  <Card.Header>
                    <Card.Title as="h3">Assignments Completed</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <h1>{Math.round(this.props.percentageCompleted * 100)}%</h1>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6">
                <Card className="text-center">
                  <Card.Header>
                    <Card.Title as="h3">Assignments Remaining</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <h1>{this.props.assignmentsRemaining}</h1>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <CreateAssignment
                  createAssignment={this.props.createAssignment}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <ImportStudents import={this.props.importStudents} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  assignmentsRemaining: assignmentsRemaining(state),
  percentageCompleted: getPercentageCompleted(state)
});

export default connect(
  mapStateToProps,
  { addStudent, importStudents, createAssignment, fetchAssignments, fetchPercentageCompleted }
)(AgencyDashboard);
