import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getAssessment } from '../../selectors/AssessmentSelectors';
import { fetchAssessment } from '../../actions/AssessmentActions';
import { getAssignmentResults } from '../../selectors/AssignmentSelectors';
import { fetchAssignmentResults } from '../../actions/AssignmentActions';
import Card from 'react-bootstrap/Card';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as Icon from 'react-feather';
import './Assignment.scss';
import { AssignmentResults } from './AssignmentResults';

const Assignment = (props) => {
  const [questionIndex, setQuestionIndex] = useState(0);

  useEffect(() => {
    if (!props.assessment) {
      props.fetchAssessment();
    }
  }, [props.assessment]);

  useEffect(() => {
    const { assignmentId } = props.match.params;

    if (assignmentId) {
      props.fetchAssignmentResults(assignmentId);
    }
  }, [props.match.params.assignmentId]);

  const changeQuestion = (x) => {
    const newIndex = questionIndex + x;
    if (newIndex >= 0 && newIndex < props.assessment.questions.length) {
      setQuestionIndex(newIndex);
    }
  };

  const changeQuestionById= (questionId) => {
    const newIndex = props.assessment.questions.findIndex(question => question.questionId === questionId);
    if (newIndex >= 0 && newIndex < props.assessment.questions.length) {
      setQuestionIndex(newIndex);
    }
  };

  let options;
  if (!props.assessment || !props.assignmentResults) {
    return null;
  }
  if (props.assessment) {
    const question = props.assessment.questions[questionIndex];
    let data = (props.assignmentResults[question.questionId])
      ? JSON.parse(JSON.stringify(props.assignmentResults[question.questionId]))
      : [];

    data = data.map((answer, idx) => {
      return { y: (answer < 0) ? 0 : answer, name: idx }
    });

    if (question) {
      options = {
        chart: {
          type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
          text: question.text
        },
        xAxis: {
          categories: [
            0,1,2,3,4,5
          ],
          crosshair: true,
          title: {
            text: 'Answer'
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of Students'
          }
        },
        tooltip: {
          formatter: function () {
            return `${this.point.y} ${pluralize('student', this.point.y)} answered ${this.point.name}`;
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          showInLegend: false,
          data
        }]
      }
    }
  }


  return (
    <div className="Assessment container">
      <Row className="justify-content-md-center">
        <Header title="Assignment Results" />
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>
              <AssignmentResults
                assignmentResults={props.assignmentResults}
                changeQuestionById={changeQuestionById}
                questions={props.assessment.questions}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Header title="Assignment Results By Question" />
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>
              <a
                className="Assignment__arrow--left"
                onClick={() => changeQuestion(-1)}
              >
                <Icon.ChevronLeft color="lightgrey" size={32} />
              </a>
              {options && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              )}
              <a
                className="Assignment__arrow--right"
                onClick={() => changeQuestion(1)}
              >
                <Icon.ChevronRight color="lightgrey" size={32} />
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  assessment: getAssessment(state),
  assignmentResults: getAssignmentResults(state, { assignmentId: props.match.params.assignmentId })
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAssessment, fetchAssignmentResults }
  )(Assignment)
);
