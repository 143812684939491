import * as types from '../constants/ActionTypes';
import { callApi } from '../utils/ApiUtils';
import Notify from '../utils/Notify';
import { redirect } from './UserActions';

export const fetchAssignments = () => async dispatch => {
  const { error, json } = await callApi('/api/assignments');

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Assessments'
    });
  } else {
    dispatch({
      type: types.FETCHED_ASSIGNMENTS,
      assignments: json
    });
  }
};

export const fetchAssignmentResults = (assignmentId) => async dispatch => {
  const { error, json } = await callApi(`/api/assignments/${assignmentId}/results`);

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Assignment Results'
    });
  } else {
    dispatch({
      type: types.FETCHED_ASSIGNMENT_RESULTS,
      assignmentId,
      assignmentResults: json
    });
  }
};

export const fetchPercentageCompleted = () => async dispatch => {
  const { error, json } = await callApi(
    '/api/data/assignments/percentage-completed'
  );

  if (error) {
    dispatch({
      type: types.ERROR,
      error: 'Error Fetching Percentage Completed'
    });
  } else {
    dispatch({
      type: types.FETCHED_PERCENTAGE_COMPLETED,
      percentageCompleted: json.percentageCompleted
    });
  }
};

export const createAssignment = startDate => async dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ startDate })
  };

  const { error, json } = await callApi('/api/assignment', options);

  if (error) {
    Notify.error('Error creating assignment');
  } else {
    Notify.success('Assignment created');
    dispatch(redirect('/assignments'))
  }
};

export const deleteAssignment = (assignmentId) => async dispatch => {
  const options = {
    method: 'DELETE'
  };

  const { error, json } = await callApi(`/api/assignments/${assignmentId}`, options);

  if (error) {
    Notify.error('Assignment already started by students. \nCannot delete');
  } else {
    Notify.success('Assignment deleted');
    dispatch({
      type: types.DELETED_ASSIGNMENT,
      assignmentId,
    });
  }
};

