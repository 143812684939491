import dateFns from 'date-fns';

export const getAssignments = state => {
  return state.assignment.assignments;
};

export const getAssignmentResults = (state, props) => {
  const { assignmentId } = props;
  return state.assignment.assignmentResults[assignmentId] || {};
};

export const getPercentageCompleted = state => {
  return state.assignment.percentageCompleted;
};

export const assignmentsRemaining = state => {
  const { assignments } = state.assignment;
  const date = new Date();

  return assignments.reduce((remaining, assignment) => {
    const result = dateFns.compareDesc(date, new Date(assignment.dueDate));
    return remaining + (result > 0 ? 1 : 0);
  }, 0);
};
