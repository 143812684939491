import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { fetchAssessments } from '../../actions/AssessmentActions';
import { fetchStudents } from '../../actions/AgencyActions';
import { getStudent } from '../../selectors/AgencySelectors';

import Assessments from '../student/Assessments';
import Container from "react-bootstrap/Container";

class Student extends Component {
  componentDidMount() {
    this.props.fetchStudents();
    this.props.fetchAssessments(this.props.match.params.studentId)
  }

  render() {
    const { student } = this.props;
    return (
      <Container>
        <Row>
          <Col className="SignUp__buttons" md="12">
            <h1 className="page-title">
              {`${student.firstName} ${student.lastName}`}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Assessments agency />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  student: getStudent(state, props.match.params)
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchAssessments, fetchStudents }
  )(Student)
);
