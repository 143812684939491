import { combineReducers } from 'redux';
import user from './User';
import agency from './Agency';
import assessment from './Assessment';
import assignment from './Assignment';

const rootReducer = combineReducers({
  agency,
  assessment,
  assignment,
  user
});

export default rootReducer;
