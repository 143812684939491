import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getUser } from '../../selectors/UserSelectors';

const PrivateRoute = ({
  component: Component,
  authenticated,
  user,
  ...rest
}) => {
  if (authenticated && !user) {
    return null;
  }

  return (
    <Route
      exact
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state, props) => ({
  user: getUser(state)
});

export default connect(mapStateToProps)(PrivateRoute);
