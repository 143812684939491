import { NotificationManager } from 'react-notifications';

export default class Notify {
  static success(message, time = 3000) {
    NotificationManager.success(message, '', time);
  }

  static error(message, time = 3000) {
    NotificationManager.error(message, '', time);
  }
}
