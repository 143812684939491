export const getAssessment = state => {
  return state.assessment.assessment;
};

export const getAssessmentResults = (state, props) => {
  return state.assessment.results[props.assessmentId] || [];
};

export const getAssessments = state => {
  return state.assessment.assessments;
};
